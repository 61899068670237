import dayjs from "dayjs";
import { useEffect, useMemo, useRef, useState } from "react";

import { DateObj, ItemType } from "@/components";
import { DATE_ISO } from "@/constants";
import { DateType, IPortfolioStrategy } from "@/types";
import { verifyIsEmptyDataFields } from "@/utils";
import { createCompareConfig, keyMapper } from "@/utils/compare";

import { useCompareData } from "./useCompareData";
import { useConvertResultData } from "./useConvertResultData";

export type ListType = {
  core: (IPortfolioStrategy & { id?: number })[];
  overlay: (IPortfolioStrategy & { id?: number })[];
  benchmark: { name: string; ticker: string }[];
  ticker: string[];
  portfolios: { name: string; id: number }[];
};

const DEFAULT_ITEMS_COUNT = 5;
const DELAY_RESULT_VIEW = 1000;

export const useCompareForm = (list: ListType) => {
  const [selected, setSelected] = useState<ItemType<string> | null>(null);
  const [selectedBase, setSelectedBase] = useState<ItemType<string> | null>(null);
  const [selectedList, setSelectedList] = useState<ItemType<string>[] | null>(null);
  const [date, setDate] = useState<DateType | null>(null);
  const resultRef = useRef<HTMLTableSectionElement | null>(null);

  const { getCompareResult, cancel, result, progress, isDataReady } = useCompareData();

  const compareHandler = () => {
    const { start, end } = date || { start: null, end: null };

    const selectedDate = date?.start
      ? date?.end
        ? { start, end }
        : { start, end: dayjs().format("YYYY-MM-DD") }
      : date?.end
      ? { start: null, end }
      : null;

    if (selectedList && selectedBase) {
      const config = createCompareConfig(selectedBase, selectedList, selectedDate, list.benchmark);
      if (config) getCompareResult(config);
    }
  };

  const { tableData, chartData, columns } = useConvertResultData({
    result,
    base: selectedBase,
    list: selectedList,
    isReady: isDataReady,
    date,
  });

  const cancelHandler = () => {
    cancel.abort();
  };

  const addStrategyHandler = () => {
    const type = keyMapper.getSubType(selected?.key || "");
    if (selected && type) {
      setSelectedList((prev) => [...(prev || []), { ...selected, type }]);
      setSelected(null);
    }
  };

  const removeHandler = (removeKey: string) => {
    setSelectedList((prev) => {
      const newList = prev?.filter(({ key }) => key !== removeKey) || null;
      return newList && newList.length ? newList : null;
    });
  };

  const selectItemHandler = (name: string, item: ItemType<string>) => {
    setSelected(item);
  };

  const selectBaseItemHandler = (name: string, item: ItemType<string>) => {
    setSelectedBase(item);
  };

  const selectDateHandler = (date: DateObj) => {
    setDate({
      start: date.start ? dayjs(date.start).format(DATE_ISO) : null,
      end: date.end ? dayjs(date.end).format(DATE_ISO) : null,
    });
  };

  const strategiesList = useMemo(() => {
    const keys = selectedList?.map(({ key }) => key) || [];
    return [
      ...list.core.map(({ name, type, id }) => ({
        key: keyMapper.createKey(name, "strategy", "core", id),
        value: name,
      })),
      ...list.overlay.map(({ name, type, id }) => ({
        key: keyMapper.createKey(name, "strategy", "overlay", id),
        value: name,
      })),
      ...list.portfolios.map(({ name, id }) => ({
        key: keyMapper.createKey(name, "portfolio", "portfolio", id),
        value: name,
      })),
      ...list.benchmark.map(({ name }) => ({
        key: keyMapper.createKey(name, "ticker", "benchmark"),
        value: name,
      })),
      ...list.ticker.map((name) => ({
        key: keyMapper.createKey(name, "ticker", "ticker"),
        value: name,
      })),
    ].filter(({ key }) => !keys.includes(key) && selectedBase?.key !== key);
  }, [list, selectedList, selectedBase]);

  const selectedBaseItem = useMemo(() => {
    return {
      name: selectedBase?.value || null,
      type: keyMapper.getSubType(selectedBase?.key || ""),
    };
  }, [selectedBase]);

  const isBenchmarkDisabled = !!(selectedList && selectedList?.length > 1);
  const isAddItemDisabled =
    !selected || !!(selectedList && selectedList?.length >= DEFAULT_ITEMS_COUNT);
  const isCompareDataDisabled =
    !selectedList || !(selectedBase ? selectedList.length : selectedList.length >= 1) || progress;
  const isCompareResultEmpty = !!(result && verifyIsEmptyDataFields(result));
  const isBacktestingResultEmpty = isCompareResultEmpty && isDataReady;

  useEffect(() => {
    if (isDataReady && resultRef.current) {
      setTimeout(() => {
        resultRef?.current?.scrollIntoView({ block: "start", behavior: "smooth" });
      }, DELAY_RESULT_VIEW);
    }
  }, [isDataReady]);

  return {
    selected,
    selectedList,
    selectedBase,
    selectedBaseItem,
    strategiesList,
    isBenchmarkDisabled,
    isAddItemDisabled,
    isCompareDataDisabled,
    isBacktestingResultEmpty,

    resultRef,

    isCompareResultEmpty,
    isDataReady,
    progress,
    columns,
    tableData,
    chartData,
    date,

    compareHandler,
    cancelHandler,
    addStrategyHandler,
    removeHandler,
    selectItemHandler,
    selectDateHandler,
    selectBaseItemHandler,
  };
};
