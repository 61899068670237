import { FC, Fragment } from "react";
import { Link } from "react-router-dom";

import { ROUTES } from "@/constants";
import { authService } from "@/services";

import styles from "./NoPageFound.module.css";

import { ReactComponent as StopIcon } from "@images/not-found.svg";

interface IProps {
  isNavigated?: boolean;
}

export const NoPageFound: FC<IProps> = ({ isNavigated }) => {
  const isUserLogined = authService.isLogined();

  return (
    <>
      <div className={styles.container}>
        <StopIcon />
        <h1 className={styles.heading}>Ooops, PAGE NOT FOUND!</h1>
        {isNavigated && (
          <Fragment>
            <div className={styles.separator}></div>
            <p className={styles.subheading}>
              Go back to
              {isUserLogined ? (
                <Link to={ROUTES.portfolioBuilder.path}>Portfolio Page</Link>
              ) : (
                <Link to={ROUTES.login.path}>Login</Link>
              )}
            </p>
          </Fragment>
        )}
      </div>
    </>
  );
};
