import { FC, Fragment, useCallback, useEffect, useMemo, useRef } from "react";

import { BacktestingEmptyResult, Heading } from "@/components";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { WarRoomService } from "@/services/warRoomService";
import { fetchAllocationPerformance, init, warRoomState } from "@/store/warRoom";
import { AssetRecordType, IConfigData, ScenarioType } from "@/types/warRoom";
import { prepareChartData, prepareTableData } from "@/utils/warRoom";

import { PerformanceData } from "./components/PerformanceData/PerformanceData";
import { ReturnsData } from "./components/ReturnsData/ReturnsData";
import { SlidersForm } from "./components/SlidersForm/SlidersForm";

import styles from "./UserWarRoom.module.scss";

import { ReactComponent as InfoIcon } from "@images/attention-info.svg";

enum FIELD_KEYS {
  ALLOC_SHARPE = "Optimized Allocation for Sharpe",
  ALLOC_RETURN = "Optimized Allocation for Return",
  ALLOC_RISK = "Optimized Allocation for Risk",

  PERF_RATIO = "Optimized for Sharpe Ratio",
  PERF_RETURN = "Optimized for Return",
  PERF_RISK = "Optimized for Risk",
}

interface IUserWarRoomProps {
  tickersList: AssetRecordType;
  type: ScenarioType;
}

const DELAY_TIME = 1000;

export const UserWarRoom: FC<IUserWarRoomProps> = ({ tickersList, type }) => {
  const dispatch = useAppDispatch();
  const { result, isLoading, error } = useAppSelector(warRoomState);
  const itemRef = useRef<HTMLDivElement | null>(null);
  const requestRef = useRef(false);
  const emptyResultRef = useRef<HTMLDivElement | null>(null);

  const {
    description,
    allocation,
    performance,
    footnote,
    period_monthly_returns,
    period_returns_table,
    period_return_note,
    benchmark_note,
  } = result || {};

  const isDataAvailable = !!(
    allocation &&
    performance &&
    Object.keys(allocation).length &&
    Object.keys(performance).length
  );

  const chartsData = useMemo(() => {
    return {
      ratio: prepareChartData(allocation?.[FIELD_KEYS.ALLOC_SHARPE]),
      return: prepareChartData(allocation?.[FIELD_KEYS.ALLOC_RETURN]),
      risk: prepareChartData(allocation?.[FIELD_KEYS.ALLOC_RISK]),
      periodMonthlyReturns: period_monthly_returns || null,
    };
  }, [allocation, period_monthly_returns]);

  const tablesData = useMemo(() => {
    return {
      ratio: prepareTableData(performance || null, FIELD_KEYS.PERF_RATIO),
      return: prepareTableData(performance || null, FIELD_KEYS.PERF_RETURN),
      risk: prepareTableData(performance || null, FIELD_KEYS.PERF_RISK),
      periodReturns: period_returns_table || null,
    };
  }, [performance, period_returns_table]);

  const assetsList = period_returns_table
    ? Object.keys(period_returns_table[0]).filter((i) => i.toLowerCase() !== "date")
    : null;

  const confirmHandler = useCallback((config: IConfigData) => {
    requestRef.current = true;
    config.scenario_type = type;
    dispatch(fetchAllocationPerformance(config)).then(() => {
      setTimeout(() => {
        itemRef.current?.scrollIntoView({ block: "start", behavior: "smooth" });
      }, 500);
    });
  }, []);

  const cancelHandler = () => {
    WarRoomService.abort.abort();
  };

  const isEmptyResultData = useMemo(() => {
    const isAbortedRequest = WarRoomService.abort.signal.aborted;
    if (
      !allocation &&
      !performance &&
      !isLoading &&
      requestRef.current &&
      !error &&
      !isAbortedRequest
    )
      return true;

    return false;
  }, [isLoading, error, WarRoomService.abort, performance, allocation]);

  useEffect(() => {
    if (isEmptyResultData) {
      // notification.info("Generated Performance data are empty.");
      setTimeout(() => {
        emptyResultRef.current?.scrollIntoView({ block: "center", behavior: "smooth" });
      }, DELAY_TIME);
    }
  }, [isEmptyResultData]);

  useEffect(() => {
    dispatch(init());

    return () => {
      WarRoomService.abort.abort();
    };
  }, []);

  return (
    <div className={styles.container}>
      <section className={styles.section}>
        <SlidersForm
          onConfirm={confirmHandler}
          onCancel={cancelHandler}
          isLoading={isLoading}
          tickersList={tickersList}
          isCustom={type === "custom"}
        />
      </section>
      {description && (
        <section className={styles.section}>
          <Heading type="h3" className={styles.subheading}>
            Scenario Description
          </Heading>
          <ScenarioInfo text={description || "No information yet."} />
        </section>
      )}
      {
        <BacktestingEmptyResult
          isVisible={isEmptyResultData}
          title="No Data was found with this desired assets configuration."
          heading="Optimal Allocations Result"
          ref={emptyResultRef}
        />
      }
      {isDataAvailable && (
        <Fragment>
          <section className={styles.section} ref={itemRef}>
            <Heading type="h3" className={styles.subheading}>
              Optimal Portfolio for Sharpe Ratio
            </Heading>
            <PerformanceData
              chartData={chartsData.ratio}
              tableData={tablesData.ratio}
              note={result?.benchmark_note}
            />
          </section>
          <section className={styles.section}>
            <Heading type="h3" className={styles.subheading}>
              Optimal Portfolio for Return
            </Heading>
            <PerformanceData
              chartData={chartsData.return}
              tableData={tablesData.return}
              note={result?.benchmark_note}
            />
          </section>
          <section className={styles.section}>
            <Heading type="h3" className={styles.subheading}>
              Optimal Portfolio for Risk
            </Heading>
            <PerformanceData
              chartData={chartsData.risk}
              tableData={tablesData.risk}
              note={result?.benchmark_note}
            />
          </section>
          <section className={styles.section}>
            <Heading type="h3" className={styles.subheading}>
              Historical Scenario Matching Periods <span className={styles.headingNote}>***</span>
            </Heading>
            <ReturnsData
              tableData={tablesData.periodReturns}
              chartData={chartsData.periodMonthlyReturns}
              assetsList={assetsList}
            />
          </section>
        </Fragment>
      )}
      {!!result && footnote && (
        <div className={styles.footnote}>
          <p>Note:</p>
          <p className={styles.note}>
            <span>*</span>
            {footnote}
          </p>
          <p className={styles.note}>
            <span>**</span>
            {benchmark_note}
          </p>
          <p className={styles.note}>
            <span>***</span>
            {period_return_note}
          </p>
        </div>
      )}
    </div>
  );
};

interface IScenarioInfoProps {
  text: string;
}

const ScenarioInfo: FC<IScenarioInfoProps> = ({ text }) => {
  return (
    <div className={styles.scenarioContainer}>
      <div className={styles.inner}>
        <div className={styles.iconContainer}>
          <InfoIcon />
        </div>
        {text}
      </div>
    </div>
  );
};
