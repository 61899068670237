import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { HomeNavLayout, HomeThemeLayout, HomeVideoThemeLayout } from "@/components";
import { ROUTES } from "@/constants";
import BgThemeImage from "@/images/themes/background-theme.png";
import {
  Disclosures,
  DemoInvite,
  ForgotPassword,
  PrivacyPolicy,
  RegistrateUser,
  UserLogin,
} from "@/pages";

import { About } from "./About/About";
import { HomeMain } from "./HomeMain/HomeMain";
import { Insights } from "./Insights/Insights";
import { LearnMore } from "./LearnMore/LearnMore";
import { Presentation } from "./Presentation/Presentation";
import { Solutions } from "./Solutions/Solutions";
import { Subscribe } from "./Subscribe/Subscribe";
import { Unsubscribe } from "./Unsubscribe/Unsubscribe";

export const HomeRoot: FC = () => {
  return (
    <HomeNavLayout>
      <Routes>
        <Route path="/" element={<HomePresentation />} />
        <Route path="/*" element={<HomeOther />} />
      </Routes>
    </HomeNavLayout>
  );
};

export const HomeOther = () => {
  return (
    <HomeThemeLayout bgImage={BgThemeImage}>
      <Routes>
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/about" element={<About />} />
        <Route path="/insights" element={<Insights />} />
        <Route path="/modeling" element={<Presentation type="modeling" />} />
        <Route path="/forecasting" element={<Presentation type="forecasting" />} />
        <Route path="/learn-more" element={<LearnMore />} />

        <Route path="/subscribe" element={<Subscribe />} />
        <Route path="/unsubscribe" element={<Unsubscribe />} />

        <Route path={ROUTES.demoRequest.path} element={<DemoInvite />} />
        <Route path={ROUTES.login.path} element={<UserLogin />} />
        <Route path={ROUTES.registrate.path} element={<RegistrateUser />} />
        <Route path={ROUTES.forgotPassword.path} element={<ForgotPassword />} />
        <Route path={ROUTES.disclosures.path} element={<Disclosures autoscroll />} />
        <Route path={ROUTES.privacyPolicy.path} element={<PrivacyPolicy autoscroll />} />
        <Route path="*" element={<Navigate to={ROUTES.root.path} replace />} />
      </Routes>
    </HomeThemeLayout>
  );
};

export const HomePresentation = () => {
  return (
    <HomeVideoThemeLayout>
      <HomeMain />
    </HomeVideoThemeLayout>
  );
};
