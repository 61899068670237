import React, { FC, Suspense, memo } from "react";

import { useAppSelector } from "@/hooks";
import { NoPageFound } from "@/pages";
import { pagesStatusState } from "@/store/pagesPermission";
import { pagePermission } from "@/utils/pagesPermissions";

import { FallBackLoader } from "../Loader/FallBackLoader";
import { NoAccess } from "../NoAccess/NoAccess";

export type PageKey =
  | "MacroPlanner-MultiAsset"
  | "MacroPlanner-EquitySectors"
  | "MacroPlanner-FixedIncome"
  | "MacroPlanner-Commodities"
  | "MacroPlanner-Custom"
  | "Portfolio-Compare"
  | "Portfolio-AllocationOptimizer"
  | "Portfolio-MultiStrategy"
  | "Portfolio-SingleStrategy"
  | "Home"
  | "Odin-Chat"
  | "Market-View"
  | "Discover-Data"
  | "Events-Calendar"
  | "ETFIntelligence"
  | "ForecastMacro"
  | "ForecastEquities"
  | "ForecastIndustries"
  | "ForecastSectors";

export const defaultComponent = (title: string, isNotFound: boolean) => {
  const NoAccessComponent = () => <NoAccess title={title} />;
  const NotFound = () => <NoPageFound />;

  return new Promise<{ default: () => JSX.Element }>((resolve) =>
    setTimeout(
      () =>
        resolve({
          default: isNotFound ? NotFound : NoAccessComponent,
        }),
      500
    )
  );
};

export const ComponentWithPermissions: FC<{
  componentLoader: () => Promise<{ default: () => JSX.Element }>;
  title: string;
  pageKey: PageKey;
}> = memo(({ componentLoader, title, pageKey }) => {
  const { pages } = useAppSelector(pagesStatusState);

  const Component = React.lazy(() => {
    const foundPage = pagePermission.findPage(pages, pageKey);

    return pagePermission.checkPageStatus(pageKey, foundPage).then((isActive) => {
      const isNotFound = pagePermission.isPageNotFound(foundPage, isActive);
      return isActive ? componentLoader() : defaultComponent(title, isNotFound);
    });
  });

  return (
    <Suspense fallback={<FallBackLoader />}>
      <Component />
    </Suspense>
  );
});
