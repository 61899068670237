import { AxiosError } from "axios";
import { useEffect, useState } from "react";

import { compareService } from "@/services/compare";
import { ICompareConfig, ICompareResponseData } from "@/types";
import { notification } from "@/utils";

export const useCompareData = () => {
  const [progress, setProgress] = useState(false);
  const [result, setResult] = useState<ICompareResponseData | null>(null);
  const [isDataReady, setIsDataReady] = useState(false);
  const cancel = compareService.abort;

  const getCompareResult = async (config: ICompareConfig) => {
    const getData = async () => {
      try {
        const { data } = await compareService.getCompareResult(config);
        setResult(data);
        setIsDataReady(true);
      } catch (err) {
        const error = err as AxiosError | { isCanceled: boolean };
        if (Object.hasOwn(error, "isCanceled")) return;
        notification.error("Something went wrong during compare data results!");
      } finally {
        setProgress(false);
      }
    };

    setProgress(true);
    setIsDataReady(false);
    getData();
  };

  useEffect(() => {
    return () => {
      cancel.abort();
    };
  }, []);

  return {
    getCompareResult,
    cancel,
    result,
    progress,
    isDataReady,
  };
};
