import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { ItemType } from "@/components";
import {
  customBasketExtraList,
  customBasketsIndicatorsList,
  customOperatorInputTypeList,
  indexTickerList,
  indicatorsType,
  instrumentList,
  tradingBasketList,
} from "@/constants";
import { useAppDispatch, useAppSelector, useScrollTop } from "@/hooks";
import {
  indicatorsApi,
  invalidateTickers,
  useGetTickersListQuery,
} from "@/services/apiQuery/strategies";
import {
  addOperator,
  changeMode,
  clearAll,
  fetchCreateCustomIndicator,
  fetchDeleteIndicator,
  fetchIndicatorData,
  fetchOperatorsList,
  fetchUpdateIndicator,
  init,
  selectIndicatorConstructor,
  setBasketTicker,
  setConstructorError,
  setDescription,
  setFunctionType,
  setIndicatorFormError,
  setInputType,
  setInstrumentType,
  setName,
  setTickerType,
} from "@/store/strategies/customIndicator";
import {
  ConstructorInputType,
  ConstructorMode,
  IndicatorsType,
  TradingInstrumentType,
} from "@/types";
import {
  canSaveOrUpdate,
  createIndicatorFormData,
  formatExitFormula,
  validateCustomIndicator,
  validateExitCustomFormula,
} from "@/utils";

export const indicatorsTypeList = indicatorsType
  .slice(0, -1)
  .map((i) => ({ ...i } as ItemType<IndicatorsType>));

export const useConstructor = (mode: ConstructorMode) => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { indicatorId } = useParams();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const {
    isLoading,
    form,
    operatorsList,
    constructor,
    error,
    isActionCompleted,
    activeOperator,
    isReplaceMode,
    fileName,
    signal,
  } = useAppSelector(selectIndicatorConstructor);

  const { isFetching, data: tickersList } = useGetTickersListQuery();

  useScrollTop();

  const backHandler = () => {
    navigate(-1);
  };

  const changeNameHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setName(ev.target.value));
  };
  const changeDescriptionHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setDescription(ev.target.value));
  };

  const selectInputTypeHandler = useCallback(
    (name: string, item: ItemType<ConstructorInputType>) => {
      dispatch(setInputType(item.key));
    },
    []
  );

  const selectInstrumentTypeHandler = useCallback(
    (name: string, item: ItemType<TradingInstrumentType>) => {
      dispatch(setInstrumentType(item.key));
    },
    []
  );

  const selectOperatorTypeHandler = (name: string, item: ItemType<string>) => {
    const foundOperator = operatorsList?.find((i) => i.operator === item.key) || null;
    dispatch(setFunctionType(foundOperator));
  };

  const selectTickerHandler = useCallback((name: string, item: ItemType<string>) => {
    dispatch(setTickerType(item.key));
  }, []);

  const selectBasketTickerHandler = useCallback((name: string, items: ItemType<string>[]) => {
    dispatch(setBasketTicker(items));
  }, []);

  const addOperatorHandler = () => {
    if (selectedOperator) {
      dispatch(addOperator({ item: selectedOperator, isFuncInstrument: true }));
    } else if (selectedTicker) {
      dispatch(addOperator({ item: selectedTicker, isFuncInstrument: true }));
    }
  };

  const clearAllHandler = () => {
    dispatch(clearAll());
  };

  const constructorModeHandler = (value: boolean) => {
    dispatch(changeMode(value));
  };

  const saveActionHandler = useCallback(
    (csvFile: File | null) => {
      const isSignal = !!csvFile || !!(signal && signal.length > 0);
      const name = form.name?.trim() || "";

      const [isValid, formError] = validateCustomIndicator(form);
      const [isFormulaValid, formula, formulaError] = validateExitCustomFormula(
        constructor,
        isSignal
      );

      if (canSaveOrUpdate({ isValid, isFormulaValid })) {
        const { formData } = createIndicatorFormData({
          name,
          formula,
          constructor,
          fileName,
          csvFile,
          isSignal,
          description: form.description?.trim() || "",
        });

        if (mode === ConstructorMode.create) {
          dispatch(fetchCreateCustomIndicator({ formData, isSignal })).then(() => {
            // dispatch(indicatorsApi.util.resetApiState());
            invalidateTickers();
          });
        } else if (mode === ConstructorMode.edit) {
          dispatch(fetchUpdateIndicator({ formData, id: indicatorId, isSignal })).then(() => {
            // dispatch(indicatorsApi.util.resetApiState());
            invalidateTickers();
          });
        }
      } else if (!isValid) {
        dispatch(setIndicatorFormError(formError));
      } else if (!isFormulaValid) {
        dispatch(setConstructorError(formulaError));
      }
    },
    [signal, fileName, form, mode, constructor]
  );

  const deleteActionHandler = () => {
    setShowModal(true);
  };

  const modalHandler = (action: "submit" | "cancel") => {
    setShowModal(false);
    if (indicatorId && action === "submit") {
      dispatch(fetchDeleteIndicator(indicatorId)).then(() => {
        dispatch(indicatorsApi.util.resetApiState());
      });
    }
  };

  const selectedInputType = useMemo(() => {
    const inputType = customOperatorInputTypeList.find((i) => i.key === form.inputType);
    return inputType || null;
  }, [form.inputType]);

  const selectedInstrumentType = useMemo(() => {
    const instrumentType = instrumentList.find((i) => i.key === form.instrumentType);
    return instrumentType || null;
  }, [form.instrumentType]);

  const selectedOperator = useMemo(() => {
    return form.functionItem
      ? { key: form.functionItem.operator, value: form.functionItem.operator }
      : null;
  }, [form]);

  const operatorsMappedList = useMemo(() => {
    return operatorsList
      ? operatorsList.map(({ operator, operator_description }) => ({
          key: operator,
          value: operator,
          title: operator,
          info: operator_description,
        }))
      : [];
  }, [form, operatorsList]);

  const tradingTickers = useMemo(() => {
    return {
      etfs: tickersList?.etfs.map((item) => ({ key: item, value: item })) || [],
      equities: tickersList?.equities.map((item) => ({ key: item, value: item })) || [],
      index: indexTickerList.map((item) => ({ key: item, value: item })),
      basket:
        [...new Set([...(tickersList?.basket || [])])].map((item) => ({
          key: item,
          value: item,
        })) || [],
      cryptos: tickersList?.cryptos.map((item) => ({ key: item, value: item })) || [],
    };
  }, [tickersList]);

  const tickersMappedList = useMemo(() => {
    if (selectedInstrumentType && selectedInstrumentType.key === "baskets")
      return customBasketsIndicatorsList;
    if (selectedInstrumentType && selectedInstrumentType.key === "cryptos")
      return tradingTickers.cryptos;
    return [...tradingTickers.equities, ...tradingTickers.etfs].sort((a, b) =>
      a.value.localeCompare(b.value)
    );
  }, [tradingTickers, selectedInstrumentType, customBasketsIndicatorsList]);

  const customBasketTickersList = useMemo(() => {
    const result = [...tradingTickers.basket].sort((a, b) => a.value.localeCompare(b.value));
    result.unshift(
      ...customBasketExtraList,
      ...(tradingBasketList.slice(0, -1) as { key: string; value: string }[])
    );
    return result;
  }, [tradingTickers]);

  const selectedTicker = useMemo(() => {
    if (selectedInstrumentType?.key === "baskets") {
      return customBasketsIndicatorsList.find((i) => i.key === form.ticker) || null;
    } else return form.ticker ? { key: form.ticker, value: form.ticker } : null;
  }, [form.ticker, selectedInstrumentType]);

  const selectedBasketTicker = useMemo(() => {
    return form.basket || [];
  }, [customBasketTickersList, form.basket]);

  const exitFormula = useMemo(() => formatExitFormula(constructor), [constructor]);

  const disableIndicators = !!(form.inputType === "function");
  const disableOperators = !!(form.inputType === "instrument");
  const disableTickers = disableIndicators;
  const isCustomBasket = !!(selectedTicker?.key === "custom-basket");
  const isBasketsInstrument = !!(selectedInstrumentType?.key === "baskets");

  const isAddActionActive =
    (selectedInputType?.key === "function"
      ? !!selectedOperator
      : selectedInputType?.key === "instrument"
      ? isCustomBasket
        ? !!form.basket
        : !!selectedTicker
      : false) &&
    (!activeOperator?.isUnerasable || activeOperator === null);

  const buttonTextAction =
    form.inputType === "function"
      ? `${isReplaceMode ? "Replace" : "Add"} Operator Function`
      : `${isReplaceMode ? "Replace" : "Add"} Instrument`;

  const isConstructorEmpty = !(constructor && constructor.length);
  const oldIndicatorName = searchParams.get("name");

  useEffect(() => {
    if (form.inputType === "function" && !operatorsList) {
      dispatch(fetchOperatorsList());
    }
  }, [form.inputType, operatorsList]);

  useEffect(() => {
    return () => {
      dispatch(init());
    };
  }, []);

  useEffect(() => {
    if (isActionCompleted === true) {
      navigate(-1);
    }
  }, [isActionCompleted]);

  useEffect(() => {
    if (mode === ConstructorMode.edit && indicatorId !== undefined) {
      dispatch(fetchIndicatorData(indicatorId));
    }
  }, [mode, indicatorId]);

  return {
    form,
    showModal,
    fileName,
    oldIndicatorName,

    backHandler,
    selectInputTypeHandler,
    selectInstrumentTypeHandler,
    selectOperatorTypeHandler,
    selectTickerHandler,
    selectBasketTickerHandler,
    addOperatorHandler,
    clearAllHandler,
    changeNameHandler,
    changeDescriptionHandler,
    saveActionHandler,
    deleteActionHandler,
    modalHandler,
    constructorModeHandler,

    selectedOperator,
    selectedTicker,
    selectedBasketTicker,

    operatorsList,
    tickersList,
    customBasketTickersList,
    tickersMappedList,
    operatorsMappedList,

    disableIndicators,
    selectedInstrumentType,
    disableOperators,
    disableTickers,
    isAddActionActive,
    buttonTextAction,
    isConstructorEmpty,
    exitFormula,
    selectedInputType,
    isLoading,
    isFetching,
    isReplaceMode,
    isCustomBasket,
    isBasketsInstrument,
    error,
  };
};
