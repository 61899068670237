import { ItemType, PageKey } from "@/components";
import { navLinks } from "@/constants";
import {
  IFolder,
  IFolderData,
  ILink,
  IProfilePagePermissionType,
  IUser,
  IUserPagesAccessControl,
  MenuItemsType,
  UserPagesAccessConfig,
} from "@/types";

export type PageItemType = {
  key: PageKey[];
  status: IProfilePagePermissionType;
  menuKey: MenuItemsType[];
};

export const pagePermissionsMapList: Record<string, PageItemType> = {
  market_view: {
    status: "active",
    key: ["Market-View"],
    menuKey: ["market-view"],
  },
  etf_intelligence: {
    status: "active",
    key: ["ETFIntelligence"],
    menuKey: ["etf-intelligence"],
  },
  events_calendar: {
    status: "active",
    key: ["Events-Calendar"],
    menuKey: ["events-calendar"],
  },
  home: {
    status: "active",
    key: ["Home"],
    menuKey: ["home"],
  },
  data: {
    status: "active",
    key: ["Discover-Data"],
    menuKey: ["data"],
  },
  portfolio_builder_single_strategy: {
    status: "active",
    key: ["Portfolio-SingleStrategy"],
    menuKey: ["single-strategy"],
  },
  portfolio_builder_multi_strategy: {
    status: "active",
    key: ["Portfolio-MultiStrategy"],
    menuKey: ["multi-strategy"],
  },
  portfolio_builder_compare: {
    status: "active",
    key: ["Portfolio-Compare"],
    menuKey: ["compare"],
  },
  portfolio_builder_allocation_optimizer: {
    status: "active",
    key: ["Portfolio-AllocationOptimizer"],
    menuKey: ["allocation"],
  },
  forecast_companies: {
    status: "active",
    key: ["ForecastEquities"],
    menuKey: ["companies"],
  },
  forecast_industries: {
    status: "active",
    key: ["ForecastIndustries"],
    menuKey: ["industries"],
  },
  forecast_sectors: {
    status: "active",
    key: ["ForecastSectors"],
    menuKey: ["sectors"],
  },
  forecast_macro: {
    status: "active",
    key: ["ForecastMacro"],
    menuKey: ["macro"],
  },
  macro_planner_multi_asset: {
    status: "active",
    key: ["MacroPlanner-MultiAsset"],
    menuKey: ["multi-asset"],
  },
  macro_planner_fixed_income: {
    status: "active",
    key: ["MacroPlanner-FixedIncome"],
    menuKey: ["fixed-income"],
  },
  macro_planner_equity_sectors: {
    status: "active",
    key: ["MacroPlanner-EquitySectors"],
    menuKey: ["equity-sectors"],
  },
  macro_planner_commodities: {
    status: "active",
    key: ["MacroPlanner-Commodities"],
    menuKey: ["commodities"],
  },
  macro_planner_custom: {
    status: "active",
    key: ["MacroPlanner-Custom"],
    menuKey: ["custom"],
  },
  forecasts: {
    status: "active",
    key: ["ForecastEquities", "ForecastIndustries", "ForecastSectors", "ForecastMacro"],
    menuKey: ["companies", "industries", "sectors", "macro", "forecasts"],
  },
  odinchat: {
    status: "active",
    key: ["Odin-Chat"],
    menuKey: ["odin-chat"],
  },
  portfolio_builder: {
    status: "active",
    key: [
      "Portfolio-SingleStrategy",
      "Portfolio-MultiStrategy",
      "Portfolio-Compare",
      "Portfolio-AllocationOptimizer",
    ],
    menuKey: ["single-strategy", "multi-strategy", "compare", "allocation", "custom", "portfolio"],
  },
  macro_planner: {
    status: "active",
    key: [
      "MacroPlanner-MultiAsset",
      "MacroPlanner-FixedIncome",
      "MacroPlanner-EquitySectors",
      "MacroPlanner-Commodities",
      "MacroPlanner-Custom",
    ],
    menuKey: ["multi-asset", "fixed-income", "equity-sectors", "commodities", "macro-planner"],
  },
  modeling: {
    status: "active",
    key: [
      // macro planner pages
      "MacroPlanner-MultiAsset",
      "MacroPlanner-FixedIncome",
      "MacroPlanner-EquitySectors",
      "MacroPlanner-Commodities",
      "MacroPlanner-Custom",
      // portfolio builder pages
      "Portfolio-SingleStrategy",
      "Portfolio-MultiStrategy",
      "Portfolio-Compare",
      "Portfolio-AllocationOptimizer",
    ],
    menuKey: [
      // macro planner menu items
      "multi-asset",
      "fixed-income",
      "equity-sectors",
      "commodities",
      "custom",
      "macro-planner",
      // portfolio builder menu items
      "single-strategy",
      "multi-strategy",
      "compare",
      "allocation",
      "portfolio",
    ],
  },
};

const focusFolderKeyMap = {
  odinchat: ["OdinChat"],
  forecasts: ["ForecastEquities", "Macro"],
  data: ["CommonApi"],
};

export class PagePermission {
  public userProfile: IUser | null = null;
  public initDataResolver: (() => void) | null = null;

  constructor() {
    //
  }

  init(profile: IUser | null) {
    this.userProfile = profile;
    if (this.initDataResolver) this.initDataResolver();
  }

  async checkPageStatus(pageKey: PageKey, foundPage: PageItemType | null): Promise<boolean> {
    return new Promise((resolve) => {
      const resolver = () => {
        if (
          this.userProfile?.id &&
          foundPage?.key.includes(pageKey) &&
          (foundPage.status === "hidden" || foundPage.status === "inactive")
        ) {
          return resolve(false);
        }
        return resolve(true);
      };
      this.initDataResolver = resolver;
      if (this.userProfile) this.initDataResolver();
    });
  }

  isPageNotFound(foundPage: PageItemType | null, result: boolean) {
    return result === false && !!foundPage && foundPage.status === "hidden";
  }

  findPage(pages: Record<string, PageItemType> | null, pageKey: PageKey) {
    return Object.values(pages || {}).find((page) => page.key.includes(pageKey)) || null;
  }

  isOdinChatActive(pages: Record<string, PageItemType> | null) {
    return pages && "odinchat" in pages ? pages["odinchat"].status === "active" : true;
  }

  getPageStatus(pages: Record<string, PageItemType> | null, pageKey: string) {
    return pages && pages[pageKey]?.status ? pages[pageKey].status : "active";
  }

  getOdinPageStatus(pages: Record<string, PageItemType> | null) {
    return this.isOdinChatActive(pages) ? "active" : "stop";
  }

  isPageHidden(status: IProfilePagePermissionType) {
    return status === "hidden";
  }

  createMenu(pages: Record<string, PageItemType> | null): ILink[] {
    const filterSubmenu = (menu: ILink) => {
      if (menu.items) {
        const visibleSubItems = menu.items.filter((sub) => sub.status !== "hidden");
        const isAllInactive = visibleSubItems.every((i) => i.status === "inactive");

        if (visibleSubItems.length === 0) menu.status = "hidden";
        if (visibleSubItems.length > 0 && isAllInactive) menu.status = "inactive";

        return { ...menu, items: visibleSubItems };
      }
      return { ...menu };
    };

    const getMenuItemStatus = (name: MenuItemsType) => {
      const foundPage = Object.values(pages || {}).find((page) => page.menuKey.includes(name));

      return foundPage?.status || "active";
    };

    const navMenuWithPermissions = navLinks
      .map((menu) => ({
        ...menu,
        status: getMenuItemStatus(menu.name),
        items: menu.items
          ? menu.items.map((sub) => ({
              ...sub,
              status: getMenuItemStatus(sub.name),
            }))
          : undefined,
      }))
      .filter((menu) => menu.status !== "hidden")
      .map(filterSubmenu);

    return navMenuWithPermissions;
  }

  prepareList(list: UserPagesAccessConfig | null): Record<string, PageItemType> {
    if (list) {
      return Object.entries(list).reduce((acc: Record<string, PageItemType>, [key, status]) => {
        if (pagePermissionsMapList[key]) {
          acc[key] = {
            ...pagePermissionsMapList[key],
            status: status as IProfilePagePermissionType,
          };
        }

        return acc;
      }, {});
    }
    return {};
  }

  getProduct(products: IUserPagesAccessControl | object) {
    const list = [
      "odinchat",
      "events_calendar",
      "home",
      "etf_intelligence",
      "forecasts",
      "market_view",
    ];
    if ("config" in products) {
      const config: UserPagesAccessConfig = {};
      if ("portfolio_builder" in products.config && "macro_planner" in products.config) {
        config.modeling = products.config.portfolio_builder;
      }
      Object.keys(products.config).forEach((key) => {
        if (list.includes(key)) {
          config[key] = products.config[key];
        }
      });
      return config;
    }
    return null;
  }

  convertConfig(config: Record<string, IProfilePagePermissionType>) {
    const configData: UserPagesAccessConfig = {};
    if ("modeling" in config) {
      configData.portfolio_builder = config.modeling;
      configData.macro_planner = config.modeling;
    }

    Object.keys(config).forEach((key) => {
      if (key !== "modeling") {
        configData[key] = config[key];
      }
    });

    return configData;
  }

  getActiveStatus(
    product: ItemType<string> | null,
    accessConfig: Record<string, IProfilePagePermissionType> | null,
    statusList: ItemType<IProfilePagePermissionType>[]
  ) {
    if (product && accessConfig && accessConfig[product.key]) {
      return {
        key: accessConfig[product.key],
        value: statusList.find((i) => i.key === accessConfig[product.key])?.value || "",
      };
    }
    return null;
  }

  canSaveStatus(
    accessConfig: Record<string, IProfilePagePermissionType> | null,
    configRef: React.MutableRefObject<Record<string, IProfilePagePermissionType> | null>
  ) {
    if (accessConfig === configRef.current) return false;

    return true;
  }
  filterFolderData(pages: Record<string, PageItemType> | null, folder: IFolder): IFolder {
    const defaultActive = {
      OdinChat: true,
      ForecastEquities: true,
      Macro: true,
      CommonApi: true,
    };

    const activePages = Object.entries(pages || {}).reduce((acc, [key, page]) => {
      if (key in focusFolderKeyMap) {
        const folderDataKeys = focusFolderKeyMap[key as keyof typeof focusFolderKeyMap].reduce(
          (result, sectionsList) => {
            result[sectionsList] = page.status === "active";
            return result;
          },
          {} as Record<string, boolean>
        );

        acc = { ...acc, ...folderDataKeys };
      }

      return acc;
    }, defaultActive as Record<string, boolean>);

    const filtered = folder.saved_data?.filter((folderData) => activePages[folderData.type]) || [];

    return { ...folder, saved_data: filtered };
  }
}

export const pagePermission = new PagePermission();
