import { createSlice, createAsyncThunk, PayloadAction, createSelector } from "@reduxjs/toolkit";

import { pagesPermissionService } from "@/services";
import { PageItemType, pagePermission } from "@/utils/pagesPermissions";

import { RootState } from ".";

interface IInitialState {
  pages: Record<string, PageItemType> | null;
}

const initialState: IInitialState = {
  pages: null,
};

export const fetchPagesStatus = createAsyncThunk("pagesPermission/fetchPagesStatus", async () => {
  try {
    const { data } = await pagesPermissionService.getUserPagesAccessStatus();
    if ("config" in data) return data.config;
    else return null;
  } catch (err) {
    return null;
  }
});

export const pagesPermissionSlice = createSlice({
  name: "pagesPermission",
  initialState,
  reducers: {
    init: (state) => {
      state.pages = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPagesStatus.fulfilled, (state, { payload }) => {
        state.pages = pagePermission.prepareList(payload);
      })
      .addCase(fetchPagesStatus.rejected, (state, { payload }) => {
        //
      });
  },
});

const root = (state: RootState) => state;

export const pagesStatusState = createSelector(root, (state) => state.pagesPermission);

export const { init } = pagesPermissionSlice.actions;

export default pagesPermissionSlice.reducer;
