import { DataLayer, UserManagement } from "@piwikpro/react-piwik-pro";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { Fragment, useEffect } from "react";
import { Routes, Route, useNavigate, Navigate, useLocation } from "react-router-dom";

import { ProtectedLayout, Loader } from "@/components";
import { ROUTES, localTZ, userSeesion } from "@/constants";
import { ModelingRoot } from "@/features";
import { useAppDispatch, useAppSelector, useFontsCheck, useUserPermissions } from "@/hooks";
import {
  UserInvite,
  EventsCalendarPermissions,
  OdinChatPermissions,
  ETFIntelligencePermissions,
  ResourcesPage,
  DiscoverDataPermissions,
  Disclosures,
  PrivacyPolicy,
  UserProfilePage,
  CompanyManagementPage,
  UsersManagementMain,
  MarketViewPermissions,
  AdminPanelPage,
  UserData,
  ForecastEquitiesPermissions,
  ForecastMacroPermissions,
  ForecastIndustriesPermissions,
  ForecastSectorsPermissions,
  FocusPermissions,
} from "@/pages";
import { authService, createAxiosInterceptor, socketService } from "@/services";

import { GlobalHistory } from "./GlobalNavigate";
import { useGetSrollBarWidth } from "./hooks/useGetSrollBarWidth";
import { getPages } from "./store/adminPanel/adminPanel";
import { setNewChatMessage } from "./store/odinchat";
import { fetchPagesStatus, init, pagesStatusState } from "./store/pagesPermission";
import { profileInfo } from "./store/profile";
import { ISocketChatMessage } from "./types/odinChat";
import { pagePermission } from "./utils/pagesPermissions";

import "@/styles/app.css";

import BgImage from "@images/bg-image.jpg";

import { HomeRoot } from "./pages/HomeRoot/HomeRoot";
import { Cookies } from "./features/Cookies/Cookies";
import { Subscribe } from "./pages/HomeRoot/Subscribe/Subscribe";
import { Unsubscribe } from "./pages/HomeRoot/Unsubscribe/Unsubscribe";
import { Insights } from "./pages/HomeRoot/Insights/Insights";

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isYesterday);
dayjs.extend(isToday);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

dayjs.tz.setDefault(localTZ);

function App() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isUserLogined = authService.isLogined();
  const isFontsReady = useFontsCheck();
  const { uid, profile } = useAppSelector(profileInfo);
  const showBgImage = isUserLogined;
  const { pages } = useAppSelector(pagesStatusState);
  const { canManageUser, canManageCompany, hasUserActionTo, canInviteUser } = useUserPermissions();

  const dispatch = useAppDispatch();

  const isOdinChatActive = pagePermission.isOdinChatActive(pages);
  useGetSrollBarWidth();

  useEffect(() => {
    const logOut = () => {
      authService.logout();
      navigate("/login", { replace: true });
      socketService.disconnect();
      userSeesion.remove();
    };
    createAxiosInterceptor(logOut);
  }, [navigate]);

  // connecting to socket.io
  useEffect(() => {
    const messageHandler = (msg: ISocketChatMessage) => {
      dispatch(setNewChatMessage(msg));
    };
    if (uid && isUserLogined && isOdinChatActive) {
      socketService.connect(uid);
      socketService.listenMessages(messageHandler);
    }
  }, [uid, isUserLogined, isOdinChatActive, dispatch]);

  useEffect(() => {
    if (profile?.email && process.env?.NODE_ENV === "production") {
      DataLayer.push({
        customerId: profile?.email,
      });
      UserManagement.setUserId(profile?.email);
    }
  }, [profile]);

  useEffect(() => {
    // get menu pages for admin panel
    if (isUserLogined) dispatch(getPages());
  }, [dispatch, isUserLogined]);

  useEffect(() => {
    // get pages permission status
    if (pages === null && isUserLogined) {
      dispatch(fetchPagesStatus());
    }
  }, [pages, isUserLogined]);

  useEffect(() => {
    if (isUserLogined) dispatch(init());
  }, [isUserLogined]);

  return (
    <div className="App" style={{ backgroundImage: `url(${showBgImage ? BgImage : ""})` }}>
      {isFontsReady ? (
        <Fragment>
          <GlobalHistory />
          {isUserLogined ? (
            <ProtectedLayout>
              <Routes>
                {/* ADMIN PANEL MENU ITEMS */}
                {canInviteUser("invite") ? (
                  <Route path={ROUTES.invite.path} element={<UserInvite noSlogan />} />
                ) : null}
                {hasUserActionTo("adminPanel") ? (
                  <Route path={ROUTES.AdminPanel.path} element={<AdminPanelPage />} />
                ) : null}
                <Route path={`${ROUTES.userData.path}/*`} element={<UserData />} />
                <Route path={ROUTES.UserProfile.path} element={<UserProfilePage />} />
                {canManageCompany("manage-company") ? (
                  <Route path={ROUTES.companyManagement.path} element={<CompanyManagementPage />} />
                ) : null}
                {canManageUser("manage-user") ? (
                  <Route
                    path={`${ROUTES.usersManagement.path}/*`}
                    element={<UsersManagementMain />}
                  />
                ) : null}

                {/* <Route path={ROUTES.home.path} element={<HomePermissions />} /> */}
                <Route path={ROUTES.marketView.path} element={<MarketViewPermissions />} />

                {/* MODELING MENU ITEMS */}
                <Route path={`${ROUTES.modelingRoot.path}/*`} element={<ModelingRoot />} />

                {/* DATA MENU ITEMS */}
                <Route path={ROUTES.eventsCalendar.path} element={<EventsCalendarPermissions />} />
                <Route path={`${ROUTES.catalog.path}/*`} element={<DiscoverDataPermissions />} />
                <Route
                  path={ROUTES.ETFIntelligence.path}
                  element={<ETFIntelligencePermissions />}
                />
                <Route path={ROUTES.focus.path} element={<FocusPermissions />} />

                {/* ODIN CHAT MENU */}
                <Route path={ROUTES.odinchat.path} element={<OdinChatPermissions />} />

                {/* FORECAST MENU ITEMS */}
                <Route
                  path={ROUTES.forecastEquities.path}
                  element={<ForecastEquitiesPermissions />}
                />
                <Route path={ROUTES.forecastMacro.path} element={<ForecastMacroPermissions />} />
                <Route
                  path={ROUTES.forecastIndustries.path}
                  element={<ForecastIndustriesPermissions />}
                />
                <Route
                  path={ROUTES.forecastSectors.path}
                  element={<ForecastSectorsPermissions />}
                />
                {/* Subscribe */}
                <Route path="/subscribe" element={<Subscribe />} />
                <Route path="/unsubscribe" element={<Unsubscribe />} />

                {/* Insights */}
                <Route path="/insights" element={<Insights theme="white" />} />

                {/* OTHER MENU ITEMS */}
                <Route path={ROUTES.resources.path} element={<ResourcesPage />} />
                <Route path={ROUTES.disclosures.path} element={<Disclosures />} />
                <Route path={ROUTES.privacyPolicy.path} element={<PrivacyPolicy />} />

                <Route path="/" element={<Navigate to={ROUTES.marketView.path} />} />
                <Route path="*" element={<Navigate to={ROUTES.marketView.path} />} />
              </Routes>
            </ProtectedLayout>
          ) : (
            <Cookies>
              <Routes>
                <Route path={`${ROUTES.root.path}*`} element={<HomeRoot />} />
                <Route path="*" element={<Navigate to={ROUTES.root.path} replace />} />
              </Routes>
            </Cookies>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <Loader black />
        </Fragment>
      )}
    </div>
  );
}

export default App;
