import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { UserService } from "@/services";
import { IUserRegistrating } from "@/types";
import { validate } from "@/utils";

type FormType = Record<string, { value: string; error: string | null }>;

const EXPIRATION_ERROR = ["The link you followed for user registration, has expired or invalid!"];

interface ICompleteStatus {
  isComplete: boolean;
  error: string[] | null;
}

export const useRegistrationForm = (formInit: FormType) => {
  const [searchParams] = useSearchParams();
  const [form, setForm] = useState(formInit);
  const [loading, setLoading] = useState(false);
  const [completeStatus, setCompleteStatus] = useState<ICompleteStatus>({
    isComplete: false,
    error: null,
  });

  const registratingUser = async (registratingData: IUserRegistrating) => {
    try {
      await UserService.registratingUser(registratingData);
      setCompleteStatus({ error: null, isComplete: true });
    } catch (err) {
      setCompleteStatus({ error: EXPIRATION_ERROR, isComplete: true });
    } finally {
      setLoading(false);
    }
  };

  const login = async () => {
    try {
      //
    } catch (err) {
      //
    }
  };

  const submitHandler = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    const { firstName, lastName, password, repassword } = form;
    const pass = password.value.trim();
    const lstName = lastName.value.trim();
    const fstName = firstName.value.trim();
    const [validatedForm, isValidForm] = validate(form);
    const [validated, isValid] = validate(validatedForm, ["password", "repassword"]);

    if (isValid && isValidForm) {
      const token = searchParams.get("token");
      if (isValid && token) {
        setLoading(true);
        registratingUser({ password: pass, first_name: fstName, last_name: lstName, token });
      } else setForm(validated);
    } else {
      setForm(validated);
    }
  };

  const changeHandler = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = ev.target.value;
    const fieldKey = ev.target.name;
    setForm((prev) => ({
      ...prev,
      [fieldKey]: { error: null, value: inputValue },
    }));
  }, []);

  return { form, login, loading, completeStatus, submitHandler, changeHandler };
};
