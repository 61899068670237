import dayjs from "dayjs";

import { ExtendedColumns, ItemType } from "@/components";
import {
  DateType,
  ICompareBaseItem,
  ICompareConfig,
  ICompareResponseData,
  ICompareSecondaryItem,
  ISeries,
  StrategyCompareItemType,
  StrategyCompareType,
} from "@/types";

const REG_EXP_TYPE = /###type-(ticker)|(portfolio)|(strategy)/i;
const REG_EXP_SUBTYPE = /###subtype-(ticker)|(portfolio)|(core)|(overlay)|(benchmark)/i;
const REG_EXP_INFO = /###info-(\w+)/i;
const REG_EXP_ID = /###id-(\d+)/i;

export const keyMapper = {
  createKey: (
    name: string,
    type: StrategyCompareType,
    subtype: StrategyCompareItemType,
    id?: number
  ) => {
    const info =
      subtype === "core" || subtype === "overlay"
        ? "Strategy"
        : subtype.charAt(0).toUpperCase() + subtype.slice(1);
    return `${name}###type-${type}${id ? "###id-" + id : ""}###subtype-${subtype}###info-${info}`;
  },
  getType: (name: string) => {
    return (name
      .match(REG_EXP_TYPE)
      ?.slice(1)
      .filter((i) => i !== undefined)
      .at(0) || null) as StrategyCompareType | null;
  },
  getID: (name: string) => {
    return (Number(name.match(REG_EXP_ID)?.at(1) || "#") || null) as number | null;
  },
  getSubType: (name: string): StrategyCompareItemType | null => {
    return (name
      .match(REG_EXP_SUBTYPE)
      ?.slice(1)
      .filter((i) => i !== undefined)
      .at(0) || null) as StrategyCompareItemType | null;
  },
  getInfo: (name: string) => {
    return (
      name
        .match(REG_EXP_INFO)
        ?.slice(1)
        .filter((i) => i !== undefined)
        .at(0) || null
    );
  },
};

export const createCompareConfig = (
  base: ItemType<string>,
  strategies: ItemType<string>[],
  date: {
    start: string | null;
    end: string | null;
  } | null,
  benchmarkList: { name: string; ticker: string }[]
): ICompareConfig | null => {
  const createItem = (item: ItemType<string> | null): ICompareBaseItem | null => {
    if (!item) return null;

    const createdItem: ICompareBaseItem = { id: "-", type: "ticker" };
    const type = keyMapper.getType(item.key);
    const id = keyMapper.getID(item.key);
    const subType = keyMapper.getSubType(item.key);

    if (!type) return null;

    if (subType === "benchmark") {
      createdItem.id = benchmarkList.find((b) => b.name === item.value)?.ticker || "-";
    } else {
      createdItem.id = (type === "portfolio" || type === "strategy") && id ? id : item.value;
    }

    createdItem.type = type;

    return createdItem;
  };

  const baseItem = createItem(base);
  if (!baseItem) return null;

  const config: ICompareConfig | null = { base: baseItem };

  if (date?.start) config.start_date = date.start;
  if (date?.end) config.end_date = date.end;

  if (strategies) {
    config.secondary = strategies
      .map((i) => createItem(i))
      .filter((i) => i !== null) as ICompareSecondaryItem[];
  }

  return config;
};

export const createCompareTableColumns = (columnNames: string[]): ExtendedColumns => {
  const cols = [
    {
      Header: "Metric Name",
      accessor: "name",
      key: "name",
      canSort: false,
      minWidth: 300,
      isTruncated: true,
    },
  ];
  const extCols = columnNames.map((name) => ({
    key: name,
    Header: name,
    accessor: name,
    canSort: false,
    minWidth: 220,
    isTruncated: true,
  }));

  cols.push(...extCols);

  return cols;
};

export const createCompareChartData = (result: ICompareResponseData, columnAliases: string[][]) => {
  try {
    const series = Object.keys(result).reduce((acc, name, index) => {
      const [_, sireName] = columnAliases[index];
      const data = {
        data: Object.entries(result[name]?.equity_line || [])
          .filter(([_, value]) => value !== null)
          .map(([date, value]) => [date, Number(value)]),
        name: sireName,
      };
      acc.push(data);
      return acc;
    }, [] as ISeries[]);
    return series;
  } catch (err) {
    return [];
  }
};

export const createCompareTableData = (result: ICompareResponseData, columnAliases: string[][]) => {
  try {
    let allMetricRows = columnAliases.reduce((acc, [name]) => {
      acc.push(...Object.keys(result[name]?.metrics || {}));
      return acc;
    }, [] as string[]);

    allMetricRows = [...new Set(allMetricRows)];

    const table = allMetricRows.map((metricName) => {
      const fields = columnAliases.reduce((acc, [keyName, alias]) => {
        acc[alias] = result[keyName]?.metrics[metricName] || "-";
        return acc;
      }, {} as Record<string, string | null>);

      return { name: metricName, ...fields } as Record<string, string | null>;
    });

    return table;
  } catch (err) {
    return [];
  }
};

export const nameReplacer = (name: string) => {
  const subType = keyMapper.getSubType(name || "");
  if (subType === null) return "";
  return subType === "core" || subType === "overlay" ? "strategy" : subType.toString();
};

export const createAliases = (
  base: ItemType<string>,
  list: ItemType<string>[],
  result: ICompareResponseData
) => {
  const strategyNames = Object.keys(result || {});
  const aliasName = `BASE ${nameReplacer(base.key).toUpperCase()}`;

  const aliases =
    list
      ?.reduce(
        (acc, item, idx) => {
          acc.push(`${nameReplacer(item.key)}-${idx + 1}`);
          return acc;
        },
        [aliasName] as string[]
      )
      .map((i, idx) => [strategyNames[idx], i?.toUpperCase()]) || null;

  return aliases;
};

export const filterResultByDate = (result: ISeries[], date: DateType) => {
  return result.map((sire) => {
    return {
      ...sire,
      data: sire.data.filter(
        ([d, v]) =>
          (date.start ? dayjs(d).isSameOrAfter(date.start) : true) &&
          (date.end ? dayjs(d).isSameOrBefore(date.end) : true)
      ),
    };
  });
};
