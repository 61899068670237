import { FC } from "react";

import { HomeLayout } from "@/components";
import { RegistratingNewUser } from "@/features";

export const RegistrateUser: FC = () => {
  return (
    <>
      <RegistratingNewUser />
    </>
  );
};
