import { FC, Fragment } from "react";

import { Button, FooterNavigation, InputItem, Loader } from "@/components";
import { Dialog } from "@/components/Dialogs/Dialog";

import { useRegistrationForm } from "./hooks/useRegistrationForm";

import styles from "./RegistratingNewUser.module.scss";

const formInit: Record<string, { value: string; error: string }> = {
  firstName: {
    value: "",
    error: "",
  },
  lastName: {
    value: "",
    error: "",
  },
  password: {
    value: "",
    error: "",
  },
  repassword: {
    value: "",
    error: "",
  },
};

const REGISTRATION_SUCCESS = [
  "You are registered successful.",
  "Please, check your email box for information.",
];

export const RegistratingNewUser: FC = () => {
  const { form, loading, completeStatus, submitHandler, changeHandler } =
    useRegistrationForm(formInit);

  const disable = !!form.lastName.error || !form.firstName.value;
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.loaderContainer}>{loading && <Loader white />}</div>

        {completeStatus.isComplete ? (
          <Fragment>
            {!loading && (
              <Dialog
                title="User Registration"
                type={completeStatus.error ? "error" : "success"}
                messages={completeStatus.error ? completeStatus.error : REGISTRATION_SUCCESS}
                buttonText={completeStatus.error ? "Close" : "Continue"}
              />
            )}
          </Fragment>
        ) : (
          <div className={styles.formContainer}>
            <p className={styles.title}>Sign Up</p>
            <form onSubmit={submitHandler}>
              <div className={styles.formInnerWrapper}>
                <div className={styles.inputWrapper}>
                  <InputItem
                    label=""
                    placeholder="First Name"
                    name="firstName"
                    type="name"
                    onChange={changeHandler}
                    error={form.firstName.error}
                    value={form.firstName.value}
                    disabled={loading}
                    themed
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <InputItem
                    label=""
                    placeholder="Last Name"
                    name="lastName"
                    type="name"
                    onChange={changeHandler}
                    error={form.lastName.error}
                    value={form.lastName.value}
                    disabled={loading}
                    themed
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <InputItem
                    label=""
                    placeholder="Password"
                    name="password"
                    type="password"
                    onChange={changeHandler}
                    error={form.password.error}
                    value={form.password.value}
                    disabled={loading}
                    themed
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <InputItem
                    label=""
                    placeholder="Confirm Password"
                    name="repassword"
                    type="password"
                    onChange={changeHandler}
                    error={form.repassword.error}
                    value={form.repassword.value}
                    disabled={loading}
                    themed
                  />
                </div>
              </div>
              <div className={styles.btnGroup}>
                <Button text="Submit" disabled={disable || loading} type="white" />
              </div>
            </form>
          </div>
        )}
      </div>

      <FooterNavigation />
    </div>
  );
};
