import { FC } from "react";
import { useDrop } from "react-dnd";

import { CreateNew, DropZone, Heading, Pagination } from "@/components";
import { PORTFOLIO_ROUTES, ROUTES } from "@/constants";
import { usePagination } from "@/hooks";
import { IPaginator, IPortfolio } from "@/types";

import { PortfolioItem } from "../PortfolioItem/PortfolioItem";

import styles from "./MyPortfolios.module.scss";

interface IProps {
  allowedDropEffect?: string;
  isFavorites?: boolean;
  hasDnDTarget?: boolean;
  placeholderComponent?: () => JSX.Element;
  portfolioList: IPortfolio[];
  paginator: IPaginator;
}

export const MyPortfolios: FC<IProps> = ({
  allowedDropEffect,
  isFavorites = false,
  hasDnDTarget,
  portfolioList,
  paginator,
}) => {
  const { page, perPage, setPage, setPerPage, perPageList } = paginator;
  const { totalPages, paginatedList } = usePagination<IPortfolio>({
    list: portfolioList,
    page,
    perPage,
  });

  const selectBackgroundColor = (isActive: boolean, canDrop: boolean) => {
    if (isActive) {
      return "backgroundOk";
    } else if (canDrop) {
      return "backgroundActive";
    }
  };

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: "box",
      drop: () => ({
        name: `${allowedDropEffect} dropZone`,
        allowedDropEffect,
      }),
      collect: (monitor: any) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [allowedDropEffect]
  );

  const isActive = canDrop && isOver;
  const backgroundColor = selectBackgroundColor(isActive, canDrop);

  return (
    <div className={styles.container}>
      {/* {!isFavorites && (
        <Heading type="h3" className={styles.subhHeading}>
          My portfolios
        </Heading>
      )} */}
      <div
        className={`${styles.wrapper} ${backgroundColor ? styles[backgroundColor] : ""} ${
          isFavorites ? styles.noPadding : ""
        }`}
        ref={drop}
      >
        <div className={styles.inner}>
          <div className={styles.listWrapper}>
            {!isFavorites && (
              <CreateNew to={ROUTES.portfolioCreate.path} text="Create New Portfolio" />
            )}
            {paginatedList?.map((portfolio, idx) => (
              <PortfolioItem
                key={portfolio.id}
                portfolio={portfolio}
                isFavorites={isFavorites}
                isMyPortfolio
                hasMenu
                idx={idx}
              />
            ))}
            {hasDnDTarget && <DropZone />}
          </div>
        </div>
        <div className={styles.paginationContainer}>
          <Pagination
            onPage={setPage}
            onChangePerPage={setPerPage}
            perPage={perPage}
            currentPage={page}
            totalCount={totalPages}
            count={portfolioList?.length || 0}
            perPageList={perPageList}
          />
        </div>
      </div>
    </div>
  );
};
