export const debounce = (cb: (...args: any[]) => void, delay: number) => {
  let id: NodeJS.Timeout | null = null;
  return (...args: any[]) => {
    if (id) clearTimeout(id);
    id = setTimeout(() => {
      cb(...args);
    }, delay);
  };
};

export const maskPhone = (value: string) => {
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{2})(\d)/g, "$1$2");
  value = value.replace(/(\d)(\d{4})$/, "$1$2");
  return value;
};

type RatingObject<T extends string> = Record<T, number>;

export const orderObject = <T extends string>(
  ratings: RatingObject<T>,
  orderedKeys: T[]
): RatingObject<T> => {
  if (!ratings) return {} as RatingObject<T>;
  return orderedKeys.reduce((orderedRatings, key) => {
    orderedRatings[key] = ratings[key];
    return orderedRatings;
  }, {} as RatingObject<T>);
};

export const isObjectEmpty = (objectName: object) => {
  return JSON.stringify(objectName) === "{}";
};
