import { createSlice, createAsyncThunk, createSelector, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { ItemType } from "@/components/Dropdown/DropdownSearch";
import { ERRORS, SUCCESS } from "@/constants";
import { Errors } from "@/constants/errors";
import { customIndicatorService } from "@/services/customIndicator";
import {
  ConstructorInputType,
  ErorrsType,
  FormErrorFieldsType,
  IConstructorForm,
  IIndicatorData,
  IOperator,
  ISignalItem,
  OperatorType,
  TradingInstrumentType,
} from "@/types";
import {
  addDefaultOperator,
  addFunctionOperator,
  appendOperatorToEnd,
  convertIndicatorData,
  createBasketItem,
  getFuncEndIndex,
  resetConstructorForm,
} from "@/utils";
import { notification } from "@/utils/notification";
import { notifyCSVError } from "@/utils/warRoom";

import { RootState } from "..";

const MAX_FORMULA_LEN = 100;
const CONSTRUCTOR_ERROR = "Too much operators! Please, remove some.";

export const recalculateIndexes = (
  constructor: IndicatorOperatorType[] | null
): IndicatorOperatorType[] | null => {
  return constructor?.map((item, index) => ({ ...item, index })) || null;
};

export interface IndicatorOperatorType extends OperatorType {
  type?: "function-operator" | "ticker";
  info?: string;
  argName?: string;
  isUnerasable?: boolean;
}

interface IInitialState {
  isLoading: boolean;
  constructor: IndicatorOperatorType[] | null;
  form: IConstructorForm;
  operatorsList: IOperator[] | null;
  error: string | null;
  isActionCompleted: null | boolean;
  activeOperator: IndicatorOperatorType | null;
  isReplaceMode: boolean;
  fileName: string | null;
  signal: ISignalItem[] | null;
}

const initialState: IInitialState = {
  isLoading: false,
  constructor: null,
  form: {
    description: null,
    instrumentType: "stocks/etfs",
    functionItem: null,
    inputType: "function",
    ticker: null,
    name: null,
    error: null,
    descriptionError: null,
    basket: null,
  },
  fileName: null,
  signal: null,
  operatorsList: null,
  error: null,
  isActionCompleted: null,
  activeOperator: null,
  isReplaceMode: true,
};

export const fetchOperatorsList = createAsyncThunk(
  "customIndicator/fetchOperatorsList",
  async () => {
    const { data } = await customIndicatorService.getOperators();
    return data.result;
  }
);

export const fetchCreateCustomIndicator = createAsyncThunk(
  "customIndicator/fetchCreateCustomIndicator",
  async (indicatorData: { formData: FormData; isSignal: boolean }, { rejectWithValue }) => {
    try {
      const { formData, isSignal } = indicatorData;
      const { data } = await customIndicatorService.createIndicator(formData, isSignal);
      return data.result;
    } catch (err) {
      const error = err as AxiosError<{ error: any }>;
      const errorCode = error?.response?.data?.error?.erorr_code as number | undefined;
      return rejectWithValue(errorCode);
    }
  }
);

export const fetchUpdateIndicator = createAsyncThunk(
  "customIndicator/fetchUpdateIndicator",
  async (
    indicatorData: { formData: FormData; id?: string; isSignal: boolean },
    { rejectWithValue }
  ) => {
    try {
      const { formData, id, isSignal } = indicatorData;
      const { data } = await customIndicatorService.updateIndicator(formData, isSignal, id);

      return data.result;
    } catch (err) {
      const error = err as AxiosError<{ error: any }>;
      const errorCode = error?.response?.data?.error?.erorr_code;
      return rejectWithValue(errorCode);
    }
  }
);

export const fetchIndicatorData = createAsyncThunk(
  "customIndicator/fetchIndicatorData",
  async (ID: string) => {
    const { data } = await customIndicatorService.getIndicatorByID(ID);
    return data.result;
  }
);

export const fetchDeleteIndicator = createAsyncThunk(
  "customIndicator/fetchDeleteIndicator",
  async (ID: string) => {
    const { data } = await customIndicatorService.deleteIndicator(ID);
    return data.result;
  }
);

export const customIndicator = createSlice({
  name: "customIndicator",
  initialState,
  reducers: {
    init: (state) => {
      state.constructor = null;
      state.form = resetConstructorForm();
      state.error = null;
      state.operatorsList = null;
      state.isActionCompleted = null;
      state.activeOperator = null;
      state.isReplaceMode = true;
      state.fileName = null;
      state.signal = null;
    },
    changeMode: (state, { payload }: PayloadAction<boolean>) => {
      state.isReplaceMode = payload;
    },
    removeCSVFile: (state) => {
      state.fileName = null;
      state.signal = null;
    },
    setName: (state, { payload }: PayloadAction<string>) => {
      state.form.name = payload;
      state.form.error = null;
      state.error = null;
    },
    setDescription: (state, { payload }: PayloadAction<string>) => {
      state.form.description = payload;
      state.form.descriptionError = null;
      state.error = null;
    },
    setInputType: (state, { payload }: PayloadAction<ConstructorInputType>) => {
      state.form.inputType = payload;
      if (payload === "instrument") state.form.functionItem = null;
      if (payload === "function") state.form.ticker = null;
    },
    setFunctionType: (state, { payload }: PayloadAction<IOperator | null>) => {
      state.form.functionItem = payload;
    },
    setInstrumentType: (state, { payload }: PayloadAction<TradingInstrumentType>) => {
      state.form.instrumentType = payload;
      state.form.ticker = null;
    },
    setTickerType: (state, { payload }: PayloadAction<string>) => {
      state.form.ticker = payload;
      if (payload === "custom-basket") state.form.basket = null;
    },
    setBasketTicker: (state, { payload }: PayloadAction<ItemType[]>) => {
      state.form.basket = [...payload];
    },
    setConstructorError: (state, { payload }: PayloadAction<string | null>) => {
      state.error = payload;
    },

    addOperator: (
      state,
      {
        payload,
      }: PayloadAction<{
        item: ItemType | null;
        isFuncInstrument?: boolean;
        isSimpleOperator?: boolean;
      }>
    ) => {
      if (!state.constructor || state.constructor?.length < MAX_FORMULA_LEN) {
        if (!state.constructor) state.constructor = [];

        const { item, isFuncInstrument, isSimpleOperator } = payload;
        const activeOperator = state.activeOperator;
        const canPastFromteLeft = !(
          activeOperator &&
          activeOperator?.isUnerasable &&
          (activeOperator.value === ")" || activeOperator.value === ",")
        );
        const isFunction = state.form.inputType === "function" && !!isFuncInstrument;
        const lastIndex = state.constructor.length;
        const isReplaceMode = state.isReplaceMode && canPastFromteLeft;
        const isTicker = !!state.form.ticker && !!isFuncInstrument;
        const operator = state.operatorsList?.find((op) => op.operator === item?.key);
        const category = state.form.instrumentType;
        const basketItem = createBasketItem({
          isSimpleOperator,
          ticker: state.form.ticker,
          basket: state.form.basket,
        });

        if (activeOperator && item) {
          const activeIndex = activeOperator.index;

          if (isFunction) {
            state.constructor = addFunctionOperator({
              constructor: state.constructor,
              activeIndex,
              operator,
              isReplaceMode,
              item,
            });
          } else {
            const result = addDefaultOperator({
              item: basketItem || item,
              category,
              activeIndex,
              isTicker,
              constructor: state.constructor,
              isReplaceMode,
            });
            state.constructor = result;
          }
          state.activeOperator = state.constructor[activeIndex];
        } else {
          if (item) {
            state.constructor.push(
              ...appendOperatorToEnd({
                item: basketItem || item,
                category,
                isFunction,
                isTicker,
                operator,
                index: lastIndex,
              })
            );
          }
          state.activeOperator = null;
        }
        state.error = null;
      } else {
        state.error = CONSTRUCTOR_ERROR;
      }
    },

    deleteOperator: (state, { payload }: PayloadAction<IndicatorOperatorType>) => {
      if (state.constructor && !payload.isUnerasable && !payload.argName) {
        if (payload.type === "function-operator")
          state.constructor.splice(payload.index, getFuncEndIndex(payload, state.constructor));
        else state.constructor.splice(payload.index, 1);

        state.constructor = recalculateIndexes(state.constructor);
        state.error = null;
        const activeIndex = state.activeOperator?.index || null;
        state.activeOperator =
          state.constructor && activeIndex !== null ? state.constructor[activeIndex] : null;
      }
    },
    changeOperatorValue: (state, { payload }: PayloadAction<{ value: string; index: number }>) => {
      const { value, index } = payload;
      if (state.constructor) state.constructor[index].numValue = value;
      state.error = null;
    },
    clearAll: (state) => {
      state.constructor = [];
      state.error = null;
      state.activeOperator = null;
    },
    setIndicatorFormError: (state, { payload }: PayloadAction<FormErrorFieldsType>) => {
      state.form.error = payload.name;
      state.form.descriptionError = payload.description;
    },
    setActiveOperator: (state, { payload }: PayloadAction<IndicatorOperatorType | null>) => {
      state.activeOperator = payload;
    },
  },
  extraReducers: (builder) => {
    // get Operator functions List
    builder
      .addCase(fetchOperatorsList.pending, (state) => {
        state.operatorsList = null;
      })
      .addCase(fetchOperatorsList.fulfilled, (state, { payload }) => {
        if (payload === null) state.operatorsList = [];
        else state.operatorsList = payload;
      })
      .addCase(fetchOperatorsList.rejected, (state) => {
        notification.error(Errors.data.get);
      });

    // create new custom indicator
    builder
      .addCase(fetchCreateCustomIndicator.pending, (state) => {
        state.isLoading = true;
        state.form.error = null;
        state.isActionCompleted = null;
      })
      .addCase(fetchCreateCustomIndicator.fulfilled, (state, { payload }) => {
        state.form.name = "";
        state.isLoading = false;
        notification.success(SUCCESS.creaeated);
        state.isActionCompleted = true;
        state.signal = null;
        state.fileName = null;
      })
      .addCase(fetchCreateCustomIndicator.rejected, (state, { payload }) => {
        if (payload === 100) notifyCSVError.notifyInvalid();
        else if (payload === 351) notification.warning(ERRORS.alreadyExist);
        else notification.error(ERRORS.createError);
        state.isLoading = false;
      });

    // Get indicator by ID
    builder
      .addCase(fetchIndicatorData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchIndicatorData.fulfilled, (state, { payload }) => {
        const { form, constructor, fileName, signal } = convertIndicatorData(payload);
        state.form = form;
        state.signal = signal && signal.length > 0 ? signal : null;
        state.fileName = fileName;
        state.constructor = constructor;
        state.isLoading = false;
      })
      .addCase(fetchIndicatorData.rejected, (state, { payload }) => {
        state.isLoading = false;
        notification.error(Errors.data.get);
      });

    // Update indicator by ID
    builder
      .addCase(fetchUpdateIndicator.pending, (state) => {
        state.isLoading = true;
        state.isActionCompleted = null;
      })
      .addCase(fetchUpdateIndicator.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isActionCompleted = true;
        state.signal = null;
        state.fileName = null;
        notification.success(SUCCESS.updated);
      })
      .addCase(fetchUpdateIndicator.rejected, (state, { payload }) => {
        if (payload === 100) notifyCSVError.notifyInvalid();
        else notification.error(ERRORS.updateError);
        state.isLoading = false;
      });

    // Delete indicator by ID
    builder
      .addCase(fetchDeleteIndicator.pending, (state) => {
        state.isLoading = true;
        state.isActionCompleted = null;
      })
      .addCase(fetchDeleteIndicator.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isActionCompleted = true;
        state.signal = null;
        state.fileName = null;
        notification.success(SUCCESS.deleted);
      })
      .addCase(fetchDeleteIndicator.rejected, (state, { payload }) => {
        state.isLoading = false;
        notification.error(ERRORS.deleteError);
      });
  },
});

const state = (state: RootState) => state;

export const selectIndicatorConstructor = createSelector(state, (state) => state.customIndicator);

export const {
  init,
  addOperator,
  changeOperatorValue,
  deleteOperator,
  clearAll,
  setInputType,
  setFunctionType,
  setInstrumentType,
  setTickerType,
  setName,
  setDescription,
  setActiveOperator,
  setBasketTicker,
  changeMode,
  removeCSVFile,

  setIndicatorFormError,
  setConstructorError,
} = customIndicator.actions;

export default customIndicator.reducer;
