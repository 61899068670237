import dayjs from "dayjs";
import { v4 as uuid } from "uuid";

import { DATE_ISO, DATE_ISO_US } from "@/constants";
import {
  IPortfoliosListResponse,
  IPortfolioBase,
  IPortfolioOverlay,
  IPortfolioBuilder,
  ISeriesData,
  IBenchmark,
  IPortfolioStrategy,
  PortfolioStrategyTypeEnum,
  ICompareResponseData,
  ISeries,
} from "@/types";
import { ValueMeta } from "@/types/forecast";

import { isObjectEmpty } from "./main";

export const preparePortfolios = (portfolios: IPortfoliosListResponse) => {
  return portfolios.portfolios;
};

export const verifyIsEmptyDataFields = <T extends object>(data: T | null) => {
  if (data === null || typeof data !== "object") return true;
  return Object.keys(data).every((key) => isObjectEmpty(data[key as keyof T] as object));
};

export const configConvertToObjectArry = (
  config: IPortfolioBase | IPortfolioOverlay | undefined,
  types?: Record<string, PortfolioStrategyTypeEnum>
): IPortfolioBuilder[] => {
  const converted: IPortfolioBuilder[] = [];
  for (let i = 0; i < Object.values(config ?? {})[0]?.length; i++) {
    converted.push({
      id: uuid(),
      name: "",
      key: config?.strategies[i] || "",
      weights: `${config?.weights[i]}` || null,
      ...(types && { type: types[config?.strategies[i] || ""] || "" }),
    });
  }
  return converted;
};

export const getPreparePortfolios = (portfolios: IPortfolioBuilder[], rebalance?: string) => {
  return portfolios.reduce(
    (acc: IPortfolioBase | IPortfolioOverlay, portfolio) => {
      acc.strategies.push(portfolio.key);
      acc.weights.push(portfolio.weights !== null ? +portfolio.weights : null);
      if (rebalance) {
        (acc as IPortfolioBase).rebalance = rebalance;
      }
      return acc;
    },
    {
      strategies: [],
      weights: [],
    }
  );
};

export const convertDateInMilliseconds = (
  data: Record<string, number | null>
): [string, number | null][] =>
  Object.entries(data).map(([date, value]) => {
    const myDate = dayjs(date).format(DATE_ISO);
    return [myDate, value];
  });

export const getSeriesData = (
  initData: Record<string, Record<string, number>>,
  defaultSelected = true,
  selectedList = ["portfolio", "benchmark"]
): ISeriesData => {
  const data = Object.entries(initData ?? {}).map(([name, valuesRec]) => {
    return {
      cropThreshold: 9999,
      data: convertDateInMilliseconds(valuesRec),
      name,
      type: "spline",
      dashStyle: name === "Forecast" ? "Dash" : "Solid",
      ...(defaultSelected && {
        visible: selectedList.includes(name),
        ...(selectedList[1] === name && {
          color: "#c42525",
        }),
      }),
    };
  });
  const dates = Object.keys(Object.values(initData || {})[0] || []);
  return {
    data,
    dates,
  };
};

export const convertDate = (
  data: Record<string, number>,
  isReversed = true,
  isHideEmpty: boolean,
  isSorted: boolean
) => {
  const list: [string, number][] = Object.entries(data || {});
  const datesList = isSorted ? list.sort((a, b) => a[0].localeCompare(b[0])) : list;

  const result = datesList
    .map((date): [string, number] => {
      const myDate = dayjs(date?.[0]).format(DATE_ISO_US);
      return [myDate, date?.[1]];
    })
    .filter((item) => (isHideEmpty ? item[1] !== null : true));
  return isReversed ? result.reverse() : result;
};

export const getSeriesDataCategory = (
  initData: Record<string, Record<string, number>>,
  {
    defaultSelected = true,
    selectedList = ["portfolio", "benchmark"],
    type = "spline",
    isReversed = true,
    isSorted = false,
    isHideEmpty = false,
  }
): { data: ISeries[]; dates: string[] } => {
  const data = Object.entries(initData ?? {}).map((item) => {
    return {
      data: convertDate(item[1], isReversed, isHideEmpty, isSorted),
      name: item[0],
      type,
      dashStyle: ["odin forecast", "forecast", "consensus", "odin"].includes(item[0].toLowerCase())
        ? "Dash"
        : "Solid",
      ...(defaultSelected && {
        visible: selectedList.includes(item[0]),
        ...(selectedList[1] === item[0] && {
          color: "#c42525",
        }),
      }),
    };
  });
  const dates = Object.keys(Object.values(initData || {})[0] || {}).sort((a, b) =>
    a.localeCompare(b)
  );
  return {
    data,
    dates,
  };
};

interface IConfigObj {
  selectedGroup: string | null;
}

export const getSeriesExtraDataCategory = (
  initData: { [key: string]: Record<string, Record<string, number | null> | ValueMeta> },
  { selectedGroup }: IConfigObj
): { data: any[]; dates: string[] } => {
  const defaultGroup = Object.keys(initData)[0];
  const metaData = initData._meta;

  const data = Object.entries(initData[selectedGroup || defaultGroup] ?? {}).map((item) => {
    return {
      data: convertDate(item[1] as Record<string, number>, true, false, false),
      name: item[0],
      type: metaData[selectedGroup || defaultGroup].type || "spline",
      dashStyle: ["odin forecast", "forecast", "consensus", "odin"].includes(item[0].toLowerCase())
        ? "Dash"
        : "Solid",
    };
  });
  const dates = Object.keys(Object.values(initData[selectedGroup || defaultGroup] || {})[0] || []);
  return {
    data,
    dates,
  };
};

export const getStrategyOptionsByType = (strategies: IPortfolioStrategy[] | undefined) => {
  if (!strategies) return [];
  return strategies.map((strategy) => ({
    value: strategy.name,
    label: strategy.name,
  }));
};

export const getBenchmarkName = (list: IBenchmark[] | null, benchmark: string): string => {
  const findBenchmark = list?.find((item) => item.ticker === benchmark);
  if (findBenchmark) {
    return findBenchmark.name;
  }
  return benchmark;
};

export const deepCompare = <T>(obj1: T, obj2: T, fieldPath = ""): boolean => {
  if (obj1 === obj2) {
    return true;
  }

  if (obj1 == null || obj2 == null) {
    return false;
  }

  if (typeof obj1 !== "object" || typeof obj2 !== "object") {
    return false;
  }

  if (Array.isArray(obj1) !== Array.isArray(obj2)) {
    return false;
  }

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      return false;
    }
    for (let i = 0; i < obj1.length; i++) {
      const newPath = `${fieldPath}[${i}]`;
      if (!deepCompare(obj1[i], obj2[i], newPath)) {
        return false;
      }
    }
  } else {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }

    for (const key in obj1) {
      if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
        const newPath = fieldPath === "" ? key : `${fieldPath}.${key}`;
        if (!deepCompare(obj1[key], obj2[key], newPath)) {
          return false;
        }
      } else {
        const newPath = fieldPath === "" ? key : `${fieldPath}.${key}`;
        if (!propertyExistsInObject(newPath, obj1[key], obj2)) {
          return false;
        }
      }
    }
  }

  return true;
};

const propertyExistsInObject = <T>(fieldPath: string, value: T, obj: any): boolean => {
  const fieldPathArray = fieldPath.split(".");
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop) && prop === fieldPathArray[0]) {
      if (fieldPathArray.length === 1 && obj[prop] === value) {
        return true;
      } else if (fieldPathArray.length > 1 && typeof obj[prop] === "object") {
        return propertyExistsInObject(fieldPathArray.slice(1).join("."), value, obj[prop]);
      }
    }
  }
  return false;
};

export const createPortfolioTableColumns = (isBenchmark: boolean) => {
  const cols = [
    {
      Header: "Metric Name",
      accessor: "metric",
      key: "name",
      canSort: false,
      minWidth: 300,
    },
    {
      Header: "Portfolio",
      accessor: "value",
      key: "value",
      canSort: false,
      minWidth: 180,
    },
  ];
  if (isBenchmark)
    cols.push({
      Header: "Benchmark",
      accessor: "benchmark",
      key: "benchmark",
      canSort: false,
      minWidth: 180,
    });

  return cols;
};

export const createAllocationsChartData = (result: ICompareResponseData) => {
  try {
    const series = Object.keys(result).reduce((acc, name) => {
      const data = {
        data: Object.entries(result[name]?.equity_line || [])
          .filter(([_, value]) => value !== null)
          .map(([date, value]) => [date, Number(value)]),
        name,
      };
      acc.push(data);
      return acc;
    }, [] as ISeries[]);
    return series;
  } catch (err) {
    return [];
  }
};

export const createAllocationsTableData = (result: ICompareResponseData) => {
  try {
    let allMetricRows = Object.keys(result).reduce((acc, name) => {
      acc.push(...Object.keys(result[name]?.metrics || {}));
      return acc;
    }, [] as string[]);

    allMetricRows = [...new Set(allMetricRows)];

    const table = allMetricRows.map((metricName) => {
      const fields = Object.keys(result).reduce((acc, keyName) => {
        const origValue = result[keyName]?.metrics[metricName];
        let value = " - ";

        if (origValue) {
          value = Number.isNaN(Number(origValue)) ? origValue : Number(origValue).toFixed(3);
        }

        acc[keyName] = value;
        return acc;
      }, {} as Record<string, string | null>);

      return { name: metricName, ...fields } as Record<string, string | null>;
    });

    return table;
  } catch (err) {
    return [];
  }
};
