import React, { FC, useEffect, useRef, useState } from "react";
import ReactPhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/style.css";

import { ReactComponent as CompanyIcon } from "@images/icons/company.svg";
import { ReactComponent as EmailIcon } from "@images/icons/email.svg";
import { ReactComponent as EyeHide } from "@images/icons/eye-close.svg";
import { ReactComponent as EyeShow } from "@images/icons/eye-open.svg";
import { ReactComponent as UserICon } from "@images/icons/user.svg";

import { TooltipWrapper } from "../Tooltip/Tooltip";

import styles from "./InputItem.module.scss";

import { ReactComponent as PhoneIcon } from "@images/phone.svg";
import { ReactComponent as SearchIcon } from "@images/search.svg";

interface IProps<U> {
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onEnter?: () => void; // New prop for handling Enter key event
  label: string;
  name: U;
  placeholder?: string;
  error?: string | null;
  value?: string;
  type: "email" | "password" | "name" | "input" | "number" | "search" | "phone" | "company";
  isRequired?: boolean;
  expandable?: boolean;
  noPaddingBottom?: boolean;
  disabled?: boolean;
  symbol?: string;
  min?: number;
  max?: number;
  helpText?: string;
  tooltipHelp?: string;
  autofocus?: boolean;
  autocomplete?: string;
  isInteger?: boolean;
  themed?: boolean;
}

const textType = ["email", "name", "company", "search", "input", "number"];
const MAX_LEN = 32;

const validateMinMaxValue = (options: {
  value: string;
  min?: number;
  max?: number;
  isInteger?: boolean;
}): string => {
  const { value, min, max, isInteger } = options;
  const regExp = isInteger ? /(^-?[0-9]+)/gi : /(^-?[0-9]+\.?([0-9]+)?)/gi;
  let newValue = value.match(regExp)?.at(0) || "";

  if (value === "-") newValue = "-";
  else {
    newValue = newValue.split("").reduce<string>((acc, ch) => {
      if (ch !== "-") {
        acc =
          !Number.isNaN(+(acc + ch)) &&
          !(ch === "0" && (acc === "0" || acc === "-0")) &&
          ((+(acc + ch) >= 0 && +(acc + ch) <= (max !== undefined ? max : Infinity)) ||
            (+(acc + ch) < 0 && +(acc + ch) >= (min !== undefined ? min : -Infinity)) ||
            acc + ch === "-0") &&
          !((acc === "-0" || acc === "0") && ch !== ".")
            ? acc + ch
            : acc;
      } else acc = acc + ch;
      return acc;
    }, "");
  }

  return newValue.slice(0, MAX_LEN);
};

export function InputItem<U extends string>({
  error,
  label,
  type,
  isRequired,
  noPaddingBottom,
  symbol = "%",
  helpText,
  tooltipHelp,
  autocomplete = "off",
  onEnter,
  autofocus,
  min,
  max,
  isInteger,
  themed,
  ...inputProps
}: IProps<U>) {
  const [toggle, setToggle] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const toggleHandler = () => {
    if (type === "password") setToggle((prev) => !prev);
  };
  const inputType = toggle || textType.includes(type) ? "text" : type;

  const wheelHandler = (e: React.WheelEvent<HTMLInputElement>) => {
    if (type === "number") e.currentTarget.blur();
  };

  const changeHadler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (type === "number") {
      event.target.value = validateMinMaxValue({ value: event.target.value, min, max, isInteger });
    }
    inputProps.onChange(event);
  };

  useEffect(() => {
    if (autofocus && inputRef.current) {
      setTimeout(() => {
        if (!inputRef.current) return;
        inputRef.current.focus();
        inputRef.current.select(); // Optional: Select the input's value
      }, 300);
    }
  }, [autofocus]);

  return (
    <div
      className={`${styles.container} ${noPaddingBottom ? "" : styles.paddingBottom} ${
        error ? styles.hasError : ""
      } ${inputProps.disabled ? styles.disabled : ""} ${themed ? styles.themed : ""}`}
    >
      {label && (
        <label htmlFor={inputProps.name} className={`${isRequired ? styles.required : ""}`}>
          <p>{label}</p>
        </label>
      )}
      <TooltipWrapper text={tooltipHelp} show={!error}>
        <div className={styles.fieldWrapper}>
          {type === "phone" ? (
            <ReactPhoneInput
              {...inputProps}
              country="us"
              inputProps={{
                name: inputProps.name,
                required: isRequired,
                autoComplete: autocomplete,
                ref: inputRef,
              }}
              onChange={(value, _, event) => {
                const phoneNumber = value ? `+${value.replace(/[^\d]/g, "")}` : "";
                const syntheticEvent = {
                  ...event,
                  target: {
                    value: phoneNumber,
                    name: event.target.name,
                  },
                };
                inputProps.onChange(syntheticEvent as React.ChangeEvent<HTMLInputElement>);
              }}
              inputClass={styles.phoneInput}
            />
          ) : (
            <input
              {...inputProps}
              type={inputType}
              className={styles.input}
              onWheel={wheelHandler}
              ref={inputRef}
              autoComplete={autocomplete}
              onChange={changeHadler}
            />
          )}
          {type !== "input" && type !== "number" && (
            <span className={styles.icon} onClick={toggleHandler}>
              {type === "email" && <EmailIcon />}
              {type === "password" && (toggle ? <EyeShow /> : <EyeHide />)}
              {type === "name" && <UserICon />}
              {type === "search" && <SearchIcon style={{ opacity: 0.3 }} />}
              {type === "company" && <CompanyIcon />}
              {type === "phone" && <PhoneIcon />}
            </span>
          )}
          {type === "number" && symbol && (
            <span className={styles.icon} onClick={toggleHandler}>
              {type === "number" && symbol}
            </span>
          )}
        </div>
      </TooltipWrapper>

      {!!error && <p className={styles.error}>{error}</p>}
      {helpText && !error && <p className={styles.helpText}>{helpText}</p>}
    </div>
  );
}
