import { FC, memo, useEffect, useRef } from "react";

import { IMenuItem, ActionsMenu, TooltipWrapper, Loader } from "@/components";
import { useAppDispatch } from "@/hooks";
import { ReactComponent as ViewPerformanceIcon } from "@/images/chart-histogram-two-filled.svg";
import { ReactComponent as CopyIcon } from "@/images/copy.svg";
import { ReactComponent as StarIcon } from "@/images/star-icon.svg";
import { changeFavoriteStatus, fetchChangeFavoriteStatus } from "@/store/strategies/my_strategies";
import { IStartegyCard } from "@/types";
import { getFormattedDate } from "@/utils";

import styles from "./StrategyItem.module.scss";

import { ReactComponent as DownloadIcon } from "@images/download.svg";
import { ReactComponent as DragDropIcon } from "@images/drag-item.svg";
import { ReactComponent as StrategyIcon } from "@images/strategy.svg";

interface IProps {
  item: IStartegyCard;
  onSelect: (item: IStartegyCard) => void;
  onSelectMenu: (menuItem: IMenuItem, item: IStartegyCard) => void;
  onPositionDownload?: (item: IStartegyCard) => void;
  hasMenu?: boolean;
  style?: object;
  isDropable?: boolean;
  isFavorites?: boolean;
  idx?: number;
  progress?: boolean;
  disabledDownload?: boolean;
}

const MENU_ITEMS: IMenuItem[] = [
  {
    icon: <CopyIcon />,
    type: "copy",
    label: "Copy",
    key: "1",
  },
  {
    icon: <ViewPerformanceIcon style={{ color: "var(--primary)" }} />,
    type: "view",
    label: "Model",
    key: "2",
    disabled: false,
  },
];

export const StrategyItem: FC<IProps> = memo(
  ({
    item,
    onSelect,
    hasMenu = false,
    style,
    onSelectMenu,
    isDropable = false,
    isFavorites,
    idx,
    onPositionDownload,
    progress,
    disabledDownload,
  }) => {
    const { id, config, name, user, created_at, updated_at, permissions, show_on_home } = item;
    const dispatch = useAppDispatch();
    const itemRef = useRef<HTMLDivElement | null>(null);

    const slelectItemHandler = (item: IStartegyCard) => {
      onSelect(item);
    };

    const fevoriteHandler = () => {
      if (isFavorites && show_on_home && itemRef.current) {
        itemRef.current.classList.remove(styles.onStartAnimated);
        setTimeout(() => {
          dispatch(changeFavoriteStatus(id));
          dispatch(fetchChangeFavoriteStatus({ value: !show_on_home, id }));
        }, 500);
      } else {
        dispatch(changeFavoriteStatus(id));
        dispatch(fetchChangeFavoriteStatus({ value: !show_on_home, id }));
      }
    };

    const animationEndHandler = (ev: React.AnimationEvent<HTMLDivElement>) => {
      if (isFavorites && show_on_home && ev.target === itemRef.current) {
        dispatch(changeFavoriteStatus(id));
        dispatch(fetchChangeFavoriteStatus({ value: !show_on_home, id }));
      }
    };

    const downloadLivePositionHandler = () => {
      if (onPositionDownload && !progress && !disabledDownload) onPositionDownload(item);
    };

    const menu = hasMenu
      ? MENU_ITEMS.filter(
          ({ type }) =>
            !((isFavorites && type === "copy") || (!permissions?.has_write && type === "edit"))
        )
      : [];

    const selectMenuHandler = (menuItem: IMenuItem) => {
      onSelectMenu(menuItem, item);
    };

    const canDownloadPosition = Boolean(onPositionDownload);

    useEffect(() => {
      setTimeout(() => {
        if (itemRef.current) itemRef.current.classList.add(styles.onStartAnimated);
      }, 50 + (idx || 0) * 35);
    }, [idx]);

    return (
      <div
        className={styles.container}
        ref={itemRef}
        style={style}
        onAnimationEnd={animationEndHandler}
      >
        <div className={styles.inner} onClick={() => slelectItemHandler(item)}>
          <div className={styles.iconContainer}>
            <StrategyIcon className={`${config.type === "overlay" ? styles.overlay : ""}`} />
          </div>
          <div className={styles.textGroup}>
            <p className={styles.title}>{name}</p>
            <p className={styles.footerText}>
              {id ? `ID: ${id} ` : ""}
              Type: {config.type}
            </p>
          </div>
          <div className={styles.infoContainer}>
            <div className={styles.iconContainer}>
              {isDropable && <DragDropIcon />}
              {!isDropable && (
                <StrategyIcon className={`${config.type === "overlay" ? styles.overlay : ""}`} />
              )}
            </div>
            <div className={styles.textContainer}>
              <p>Created By </p>
              {user?.email ? <p>{user.email}</p> : <p>BDF Company</p>}
              {created_at ? (
                <p>{getFormattedDate(created_at)}</p>
              ) : (
                !user?.email && updated_at && <p>{getFormattedDate(updated_at)}</p>
              )}
            </div>
          </div>
        </div>
        {hasMenu && <ActionsMenu menuItems={menu} onSelect={selectMenuHandler} />}
        {canDownloadPosition && (
          <div
            className={`${styles.downloadActionContainer} ${
              !progress && disabledDownload ? styles.disabled : ""
            }`}
            onClick={downloadLivePositionHandler}
          >
            {progress ? (
              <Loader small />
            ) : (
              <TooltipWrapper text="Download Live Positions">
                <DownloadIcon />
              </TooltipWrapper>
            )}
          </div>
        )}
        <div className={styles.favIconContainer} onClick={fevoriteHandler}>
          <TooltipWrapper
            text={show_on_home ? "Click to hide from Home Page" : "Click to show on Home Page"}
          >
            <StarIcon className={`${show_on_home ? styles.active : ""}`} />
          </TooltipWrapper>
        </div>
      </div>
    );
  }
);
