import dayjs from "dayjs";
import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CreateNew, Heading, IMenuItem, Pagination } from "@/components";
import { DATE_ISO, ROUTES } from "@/constants";
import { useDownloadLivePositions } from "@/features/StrategyCreate";
import { useAppDispatch, usePagination } from "@/hooks";
import { fetchCopyItem } from "@/store/strategies/my_strategies";
import { IPaginator, IStartegyCard } from "@/types";

import { StrategyItem } from "../StrategyItem/StrategyItem";

import styles from "./MyStrategies.module.scss";

interface IProps {
  isFavorites?: boolean;
  strategiesList: IStartegyCard[];
  paginator: IPaginator;
}

export const MyStrategies: FC<IProps> = ({ isFavorites = false, strategiesList, paginator }) => {
  const { page, perPage, setPage, setPerPage, perPageList } = paginator;
  const { totalPages, paginatedList } = usePagination<IStartegyCard>({
    list: strategiesList,
    page,
    perPage,
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const selectHandler = useCallback((item: IStartegyCard) => {
    navigate(`${ROUTES.strategiesBuilder.path}/edit/${item.id}?name=${item.name}`);
  }, []);

  const selectMenuHandler = useCallback((menuItem: IMenuItem, item: IStartegyCard) => {
    if (menuItem.type === "copy") {
      dispatch(fetchCopyItem(item));
    }
    if (menuItem.type === "edit") {
      navigate(`${ROUTES.strategiesBuilder.path}/edit/${item.id}`);
    }
    if (menuItem.type === "view") {
      navigate(`${ROUTES.strategiesPreview.path}/${item.id}?name=${encodeURIComponent(item.name)}`);
    }
  }, []);

  return (
    <div className={styles.container}>
      {/* {!isFavorites && (
        <div className={styles.headingGroup}>
          <Heading type="h3" className={styles.heading}>
            My strategies
          </Heading>
        </div>
      )} */}

      <div className={`${styles.wrapper} ${isFavorites ? styles.noPadding : ""}`}>
        <StrtaegiesList
          isFavorites={isFavorites}
          strategiesList={paginatedList}
          selectHandler={selectHandler}
          selectMenuHandler={selectMenuHandler}
        />
        <div className={styles.paginationContainer}>
          <Pagination
            count={strategiesList?.length || 0}
            perPageList={perPageList}
            totalCount={totalPages}
            perPage={perPage}
            currentPage={page}
            onChangePerPage={setPerPage}
            onPage={setPage}
          />
        </div>
      </div>
    </div>
  );
};

interface IStrtaegiesListProps {
  isFavorites: boolean;
  strategiesList: IStartegyCard[];
  selectHandler: (item: IStartegyCard) => void;
  selectMenuHandler: (menuItem: IMenuItem, item: IStartegyCard) => void;
}

export const StrtaegiesList: FC<IStrtaegiesListProps> = ({
  isFavorites,
  strategiesList,
  selectHandler,
  selectMenuHandler,
}) => {
  const [downloadCSV, progress] = useDownloadLivePositions();
  const [progressId, setProgressId] = useState<number | null>(null);

  const downloadLivePositionHandler = useCallback((item: IStartegyCard) => {
    const fileName = `${item.name}_live_position_${dayjs().format(DATE_ISO)}`;
    downloadCSV(item.id, fileName);
    setProgressId(item.id);
  }, []);

  useEffect(() => {
    if (!progress) setProgressId(null);
  }, [progress]);

  return (
    <div className={styles.inner}>
      <div className={styles.listWrapper}>
        {!isFavorites && (
          <div className={styles.createNewItem}>
            <CreateNew
              className={styles.newItem}
              to={ROUTES.strategyCreate.path}
              text="Create New Strategy"
            />
          </div>
        )}
        {strategiesList?.map((strategy, idx) => (
          <StrategyItem
            item={strategy}
            onSelect={selectHandler}
            onPositionDownload={downloadLivePositionHandler}
            key={`${strategy.id}-${idx}`}
            style={{ maxWidth: "100%", minWidth: "100%" }}
            isFavorites={isFavorites}
            onSelectMenu={selectMenuHandler}
            idx={idx}
            progress={progressId === strategy.id}
            disabledDownload={progress}
            hasMenu
          />
        ))}
      </div>
    </div>
  );
};
