import { ChangeEvent, FC } from "react";

import styles from "./Checkbox.module.scss";

interface IProps {
  id?: string;
  label: string;
  checked?: boolean;
  onChange?: (e: boolean, name?: string) => void;
  disabled?: boolean;
  size?: "small" | "medium";
  name?: string;
}

export const Checkbox: FC<IProps> = ({
  id,
  label,
  checked,
  onChange,
  disabled,
  size,
  ...props
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e.target.checked, props.name);
  };

  return (
    <div className={styles.checkboxWrapper}>
      <input
        className={`${size === "small" ? styles.small : styles.medium}`}
        disabled={disabled}
        id={id}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        {...props}
      />
      <label htmlFor={id} className={`${styles.label} ${size === "small" ? styles.small : ""}`}>
        {label}
      </label>
    </div>
  );
};
